import React from "react";

import { useStateContext } from "../contexts/ContextProvider";
import { IoCloseSharp } from "react-icons/io5";
import { GiCheckMark } from "react-icons/gi";

const Popup = () => {
  const { activePopup, setActivePopup, popupDetails } = useStateContext();

  const handlePopup = () => {
    setActivePopup(!activePopup);
  };

  return (
    <>
      {activePopup && (
        <>
          <div //Overlay
            className="h-screen w-full fixed bg-gray-700 bg-opacity-70"
            onClick={() => handlePopup()}
          ></div>
          <div className="h-screen w-full md:w-2/5 border-l-2 border-black fixed top-0 right-0 bg-white z-10 overflow-auto">
            <div className="flex justify-end m-5 text-3xl">
              <IoCloseSharp
                className="rounded-2xl hover:bg-gray-200"
                onClick={handlePopup}
              />
            </div>
            <div className="grid justify-items-center">
              <img
                className={`rounded-lg full ${
                  popupDetails.name === "AdonisJS" ? "h-28" : "h-48"
                } mt-10 mx-10 object-cover `}
                src={popupDetails.image}
                alt={`${popupDetails.altName} - Logo | William Zero`}
              />
              <div className="grid justify-items-center w-full">
                <ul className="grid grid-cols-2 gap-y-5 gap-x-2 mt-5 mx-5">
                  {popupDetails.features.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={
                          item === "Actively Maintained"
                            ? "flex col-span-2"
                            : "flex"
                        }
                      >
                        <GiCheckMark className="text-2xl text-green-500" />
                        <div className="text-lg font-bold">{item}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="grid justify-items-center w-full">
                <div className="flex text-base  font-bold mt-10">Used By:</div>
                <ul className="flex mt-5">
                  {popupDetails.used_by.map((item, index) => {
                    return (
                      <li key={index} className="flex mx-5">
                        <img
                          className="rounded-lg full h-10 object-cover"
                          src={item}
                          alt={`A company that uses ${popupDetails.altName} | William Zero`}
                        />
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="text-justify  font-bold my-5 p-7 border bg-gray-200 rounded-2xl w-96">
                <p>{popupDetails.description}</p>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Popup;
