import React from "react";
import { DateTime } from "luxon";
import { links } from "../data/dummyData";

import { NavHashLink } from "react-router-hash-link";

const dt = DateTime.local();

const Footer = () => {
  const normalLink =
    "flex justify-center p-3 align-center rounded-lg text-md text-white hover:text-black hover:bg-light-gray";

  return (
    <div className="bg-black w-full">
      <div className="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-10 w-full text-center py-14 px-10">
        {links.map((item, index) => {
          return (
            <NavHashLink
              to={`${item.target}`}
              key={item.id}
              className={normalLink}
            >
              {item.name.replace(/-/g, " ").toUpperCase()}
            </NavHashLink>
          );
        })}
      </div>
      <div className="flex justify-center text-white bg-black pb-10">
        All Rights Reserved. &copy; William Zero 2019-{dt.toFormat("yy")}.
      </div>
    </div>
  );
};

export default Footer;
