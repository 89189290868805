import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

const initialFaqState = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
  6: false,
};

export const ContextProvider = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [activePopup, setActivePopup] = useState(false);
  const [popupDetails, setPopupDetails] = useState([]);
  const [activeIframe, setActiveIframe] = useState(false);
  const [faqClicked, setFaqClicked] = useState(initialFaqState);

  const handleFaqClick = (clicked) => {
    setFaqClicked({ ...initialFaqState, [clicked]: !faqClicked[clicked] });
  };

  return (
    <StateContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        activePopup,
        setActivePopup,
        popupDetails,
        setPopupDetails,
        activeIframe,
        setActiveIframe,
        faqClicked,
        setFaqClicked,
        handleFaqClick,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
