import React from "react";

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <button
    type="button"
    onClick={() => customFunc()}
    style={{ color }}
    className={`relative text-3xl rounded-full p-3 ${
      title === "Sidebar" ? "hover:bg-transparent" : "hover:bg-light-gray"
    }`}
  >
    <span
      style={{ background: dotColor }}
      className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
    />
    {icon}
  </button>
);

export default NavButton;
