import React, { useState, useEffect } from "react";
import axios from "axios";

import BounceLoader from "react-spinners/BounceLoader";
import { MdMarkEmailRead } from "react-icons/md";
import { BiSolidErrorAlt } from "react-icons/bi";

const BACKEND_API = process.env.REACT_APP_BACKEND_API;
const maxChars = 1000;

const Form = () => {
  const [status, setStatus] = useState(null);
  const [error, setError] = useState(null);
  const [charCount, setCharCount] = useState(maxChars);
  const [inquirySent, setInquirySent] = useState(false);
  const [inquiryFailed, setInquiryFailed] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    if (status === 201) {
      setFormData({
        name: "",
        email: "",
        company: "",
        message: "",
      });
      setInquirySent(true);
      setStatus(null);
    }
    if (error !== null) {
      setInquiryFailed(true);
      setError(null);
    }
  }, [error, status]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
    if (name === "message") {
      setCharCount(maxChars - value.length);
    }
  };

  const handleSubmit = async () => {
    if (!formHasErrors()) {
      await apiCall(formData);
    } else {
      console.log("Form Has Errors");
    }
  };

  const apiCall = async (details) => {
    setShowSpinner(true);
    await axios
      .post(`${BACKEND_API}/new-inquiry`, {
        details,
      })
      .then((response) => {
        setStatus(response.status);
      })
      .catch((error) => {
        setError(
          error.response.data.message
            ? error.response.data.message
            : error.message
        );
      });
    setShowSpinner(false);
  };

  const formHasErrors = () => {
    let errors = false;
    const regex = new RegExp(
      "[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{1,}[.]{1}[a-zA-Z0-9]{2,}"
    );
    if (formData.name === "") {
      errors = true;
      setFormErrors((prevState) => ({
        ...prevState,
        name: "animate-shake border-red-600",
      }));
      clearErrors("name");
    }
    if (formData.email === "" || !regex.test(formData.email)) {
      errors = true;
      setFormErrors((prevState) => ({
        ...prevState,
        email: "animate-shake border-red-600",
      }));
      clearErrors("email");
    }
    if (formData.message === "") {
      errors = true;
      setFormErrors((prevState) => ({
        ...prevState,
        message: "animate-shake border-red-600",
      }));
      clearErrors("message");
    }
    return errors;
  };

  const clearErrors = (item) => {
    setTimeout(() => {
      setFormErrors((prevState) => ({
        ...prevState,
        [item]: "",
      }));
    }, 1000);
    clearTimeout();
  };

  return (
    <div className="w-full">
      <div className="text-center text-3xl md:text-4xl font-bold">
        Contact Us
      </div>
      {showSpinner && (
        <div className="w-full bg-gray-700 opacity-40 fixed bottom-0">
          <div className="h-screen flex justify-center items-center text-white">
            <BounceLoader color="#FFFFFF" />
          </div>
        </div>
      )}
      {inquirySent && (
        <div className="flex justify-center w-full">
          <div className="grid justify-items-center text-center text-xl md:text-3xl font-bold w-3/5 md:w-2/5 mx-20 my-10 p-10 rounded-2xl border-2 border-black">
            <div>Thank You!</div>
            <div className="pt-5">Your message has been sent.</div>
            <MdMarkEmailRead className="text-6xl text-green-400 mt-5" />
          </div>
        </div>
      )}
      {inquiryFailed && (
        <div className="flex justify-center w-full">
          <div className="grid justify-items-center text-center text-lg md:text-3xl font-bold w-3/5 md:w-2/5 mx-20 my-10 p-5 rounded-2xl border-2 border-black">
            <div className="mt-10">
              There was an error! Please try again later.
            </div>
            <BiSolidErrorAlt className="text-6xl text-red-400 my-10" />
          </div>
        </div>
      )}

      {!inquirySent && !inquiryFailed && (
        <>
          <div className="flex justify-center">
            <p className="text-center md:text-lg md:w-2/5 px-10 pt-5">
              We do our best to respond to all inquiries within 60-minutes.
            </p>
          </div>
          <div className="flex justify-center">
            <form className="grid w-full md:w-2/5 sm:w-4/5">
              <label className="grid px-5 pt-5">
                <div>
                  Name:<span className="text-red-500">*</span>
                </div>
                <input
                  className={`border-2 border-black pl-5 py-2 rounded-xl ${formErrors.name}`}
                  type="text"
                  name="name"
                  value={formData.name}
                  placeholder="Name"
                  onChange={handleChange}
                  maxLength="60"
                />
              </label>
              <label className="grid px-5 pt-5">
                <div>
                  Email:<span className="text-red-500">*</span>
                </div>
                <input
                  className={`border-2 border-black pl-5 py-2 rounded-xl ${formErrors.email}`}
                  type="email"
                  name="email"
                  value={formData.email}
                  placeholder="your@email.com"
                  onChange={handleChange}
                  maxLength="255"
                />
              </label>
              <label className="grid px-5 pt-5">
                Company Name:
                <input
                  className="border-2 border-black pl-5 py-2 rounded-xl"
                  type="text"
                  name="company"
                  value={formData.company}
                  placeholder="Company Name"
                  onChange={handleChange}
                  maxLength="60"
                />
              </label>
              <label className="grid px-5 py-5">
                <div>
                  Tell Us About Your Project:
                  <span className="text-red-500">*</span>
                </div>
                <textarea
                  className={`border-2 border-black p-5 py-2 h-48 text-justif rounded-xl ${formErrors.message}`}
                  type="text"
                  name="message"
                  value={formData.message}
                  placeholder="Give us a brief description of your business or idea."
                  onChange={handleChange}
                  maxLength={maxChars}
                />
                <div className="flex justify-end mr-2">({charCount})</div>
                <div className="flex justify-center pt-5">
                  <button
                    type="button"
                    className="bg-black text-xl text-white rounded-xl h-14 px-5 hover:bg-white hover:text-black hover:border-4 hover:border-black active:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300"
                    onClick={() => handleSubmit()}
                  >
                    SUBMIT
                  </button>
                </div>
              </label>
            </form>
          </div>
        </>
      )}
    </div>
  );
};

export default Form;
