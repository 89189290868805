import reactjs from "../data/reactjs.jpg";
import nodejs from "../data/nodejs.jpg";
import adonis from "../data/adonis.jpg";
import postgreSQL from "../data/postgresql.jpg";
import tailwind from "../data/tailwind.jpg";
import instagram from "../data/instagram.jpg";
import reddit from "../data/reddit.jpg";
import spottify from "../data/spottify.jpg";
import twitch from "../data/twitch.jpg";
import meta from "../data/meta.jpg";
import netflix from "../data/netflix.jpg";
import airbnb from "../data/airbnb.jpg";
import uber from "../data/uber.jpg";
import linkedin from "../data/linkedin.jpg";
import paypal from "../data/paypal.jpg";
import wonolo from "../data/wonolo.jpg";
import hashnode from "../data/hashnode.jpg";
import superchat from "../data/superchat.jpg";
import gigkloud from "../data/gigkloud.jpg";
import renault from "../data/renault.jpg";
import walmart from "../data/walmart.jpg";
import marieclaire from "../data/marieclaire.jpg";

export const techItems = [
  {
    name: "ReactJS",
    altName: "React JS",
    image: reactjs,
    description:
      "ReactJS is one of the most popular front-end JavaScript libraries for building Web applications. It is actively maintained by Meta and a community of skilled developers and companies. It is known to be fast, scalable, simple, and highly advantageous to be used to create large web applications where we can change data without reloading the page.",
    features: ["Scalability", "Extremly Fast", "Actively Maintained"],
    used_by: [meta, netflix, instagram, airbnb],
  },

  {
    name: "Tailwind CSS",
    altName: "Tailwind CSS",
    image: tailwind,
    description:
      "Tailwind CSS is a Utility-first CSS framework for building rapid custom UI. It is a highly customizable, low-level CSS framework that gives you all of the building blocks that you need. Also, it is a cool way to write inline styling and achieve an awesome interface without writing a single line of your own CSS.",
    features: [
      "Customizable",
      "Easy To Maintain",
      "Easy To Update",
      "Very Popular",
    ],
    used_by: [wonolo, netflix, hashnode, superchat],
  },
  {
    name: "NodeJS",
    altName: "Node JS",
    image: nodejs,
    description:
      "Node.js is a cross-platform, open-source JavaScript runtime environment that can run on Windows, Linux, Unix, macOS, and more. Node.js runs on the V8 JavaScript engine, and executes JavaScript code outside a web browser. Node.js lets developers use JavaScript to write command line tools and for server-side scripting.",
    features: [
      "Scalability",
      "High Performance",
      "Adapatability",
      "Fast Chaching",
    ],
    used_by: [uber, netflix, linkedin, paypal],
  },
  {
    name: "PostgreSQL",
    altName: "PostgreSQL",
    image: postgreSQL,
    description:
      "PostgreSQL is the world's most advanced open-source relational database. With over 35 years of active development that has earned it a strong reputation for reliability, feature robustness, and performance.",
    features: [
      "Flexibility",
      "Scalability",
      "Data Integrity",
      "Widely Maintained",
    ],
    used_by: [instagram, reddit, spottify, twitch],
  },
  {
    name: "AdonisJS",
    altName: "Adonis JS",
    image: adonis,
    description:
      "AdonisJS is a TypeScript-first MVC (Model, Views, Controller) framework for building powerful backend servers within a Node JS environment. The validation layer of the framework outperforms other popular validation libraries in the Node JS ecosystem. It comes with support for testing, modern tooling, an ecosystem of official packages, and more.",
    features: [
      "Scalability",
      "Extremely Fast",
      "Extremely Secure",
      "Widely Maintained",
    ],
    used_by: [gigkloud, renault, walmart, marieclaire],
  },
];

export const faqItems = [
  {
    id: 1,
    question: "How long does it take to build an MVP?",
    answer:
      "It depends on the number of features and the complexity of your project, but the average is between 4 to 6 weeks.",
  },
  {
    id: 2,
    question: "How much will my MVP cost?",
    answer:
      "The price largely depends on the number of features, intergrations, and the complexity, but in general, the average MVP ranges between $15,000 and $25,000.",
  },
  {
    id: 3,
    question: "Will I own my code outright?",
    answer:
      "Absolutely!  Once your project has been delivered, you will own 100% of the code--and all of the rights to it.",
  },
  {
    id: 4,
    question:
      "My idea is highly sensitive--will you share it with anyone else?",
    answer:
      "Your project, and all of its related details, will be held in strict confidence--we will not share any details related to your project without your permission.",
  },
  {
    id: 5,
    question: "Will you help us maintain our app after it is built?",
    answer: "Yes! Ask us about our continued support options.",
  },
  {
    id: 6,
    question:
      "Would you consider building our app in exchange for shareholder equity?",
    answer:
      "Not for the initial build. However, if your project is growing quickly and you find you are requiring more continued support than you had planned for, we would be open to taking on a limited CTO role in exchange of equity.",
  },
];

export const links = [
  {
    id: 1,
    name: "home",
    target: "#home",
  },
  { id: 2, name: "tech-stack", target: "#tech-stack" },
  { id: 3, name: "what's-an-mvp", target: "#why" },
  { id: 4, name: "how-it-works", target: "#how-it-works" },
  { id: 5, name: "faq", target: "#faq" },
  { id: 6, name: "contact", target: "#contact" },
];
