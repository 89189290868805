import React from "react";

import Footer from "../components/Footer";
import Popup from "../components/Popup";
import Iframe from "../components/Iframe";
import Form from "../components/Form";
import Faq from "../components/Faq";
import { GiCheckMark } from "react-icons/gi";
import xcode from "../data/xcode.jpg";

import { useStateContext } from "../contexts/ContextProvider";
import { techItems } from "../data/dummyData";

const Home = () => {
  const {
    activePopup,
    setActivePopup,
    setPopupDetails,
    activeIframe,
    setActiveIframe,
  } = useStateContext();

  const handlePopup = (details) => {
    setActivePopup(!activePopup);
    setPopupDetails(details);
  };

  const handleClick = () => {
    setActiveIframe(!activeIframe);
  };

  const appTypes = [
    "SAAS",
    "Marketplace",
    "Delivery",
    "Subscription",
    "CRM",
    "Social Media",
  ];
  return (
    <>
      <Popup />
      <Iframe />
      <section id="home" className="w-full pt-24">
        <div className="text-3xl md:text-5xl text-center mt-5 font-bold">
          MVP's Starting at $15k:
        </div>
        <div className="flex justify-center">
          <p className="text-center text-xl md:text-2xl w-full md:w-3/5 p-5 pb-7">
            Fully scalable web and mobile apps, built using modern technology
            and industry standard practices.
          </p>
        </div>

        <div className="flex justify-center">
          <button
            type="button"
            className="bg-black text-xl text-white rounded-xl h-16 px-5 hover:bg-white hover:text-black hover:border-4 hover:border-black active:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300"
            onClick={() => handleClick()}
          >
            GET STARTED
          </button>
        </div>
        <div className="text-2xl md:text-3xl text-center mt-10 font-bold">
          What Can We Build For You?
        </div>
        <div className="grid justify-items-center w-full p-2">
          <ul className="grid grid-cols-3 w-full md:w-4/5 pt-5">
            {appTypes.map((item, index) => {
              return (
                <li key={index} className="flex justify-center pb-5">
                  <GiCheckMark className="text-2xl text-green-500" />
                  <div>{item}</div>
                </li>
              );
            })}
          </ul>
        </div>

        <div className="flex justify-center">
          <img
            className="rounded-lg full h-44 md:h-64 object-cover mt-5"
            src={xcode}
            alt="Moving Fast | William Zero"
          />
        </div>
        <div className="text-2xl md:text-3xl text-center font-bold text-wrap w-full p-5">
          Show Your Investors You Can Move Fast!
        </div>
        <p className="text-center text-lg px-5">
          Most projects completed in <span className="font-bold">6-weeks</span>{" "}
          or less!
        </p>
      </section>
      <div id="tech-stack" className="pt-14"></div>
      <section className="pt-10 w-screen bg-gray-100">
        <div className="text-center text-3xl md:text-4xl font-bold">
          Modern Tech Stack
        </div>
        <div className="flex justify-center">
          <p className="text-center text-lg p-5 md:text-3xl md:w-4/5">
            Ensures your product is easy to maintain for years to come--any
            professional developer can pick up where we leave off.
          </p>
        </div>
        <div className="flex justify-center">
          <ul className="grid grid-cols-3 grid-rows-2 justify-items-center text-center gap-x-3 gap-y-10 m-5">
            {techItems.map((item, index) => {
              return (
                <li
                  key={index}
                  className={item.name === "AdonisJS" ? "col-span-2" : ""}
                >
                  <div
                    className="hover:drop-shadow-2xl"
                    onClick={() => handlePopup(item)}
                  >
                    <img
                      className="rounded-lg full h-16 md:h-40 mt-2 object-cover"
                      src={item.image}
                      alt={`${item.altName} - Logo | William Zero`}
                    />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div id="why" className="pb-14"></div>
      </section>
      <section className="w-full pt-10">
        <div className="text-3xl md:text-5xl text-center mt-5 font-bold">
          Why Build an MVP?
        </div>
        <div className="flex justify-center w-full">
          <p className="text-justify p-7 md:text-center md:text-xl md:w-3/5">
            An MVP or Minimal Viable Product, allows you to showcase your
            concept to investors, while only spending the minimal amount of time
            and money on development. Your MVP includes all the fundemental
            architecture of a large enterprise application. New features can
            easily be added without having to rebuild from scratch.
          </p>
        </div>
        <div className="text-3xl text-center mt-10 font-bold">
          Did You Know?
        </div>
        <div className="flex justify-center py-10 px-10 w-full">
          <ul className="text-center md:text-xl md:w-3/5">
            <li className="">
              1) Most Investors won't invest in a idea without a working
              prototype, or revenue?
            </li>
            <li className="mt-10">
              2) The most succesful startups begin with an MVP, and use customer
              feedback drive new features?
            </li>
          </ul>
        </div>
        <div className="flex justify-center mt-0">
          <button
            type="button"
            className="bg-black text-xl text-white rounded-xl h-16 px-5 hover:bg-white hover:text-black hover:border-4 hover:border-black active:bg-gray-700 focus:outline-none focus:ring focus:ring-gray-300"
            onClick={() => handleClick()}
          >
            Free Consultation
          </button>
        </div>
        <p className="flex justify-center pt-3">
          (Book a FREE 30-Min Consultation)
        </p>
        <div id="how-it-works" className="pb-14"></div>
      </section>
      <section className="pt-10 w-full bg-gray-100">
        <div className="text-center text-2xl md:text-5xl font-bold">
          How it Works
        </div>
        <div className="flex justify-center">
          <p className="text-justify md:text-xl pt-10 px-10 md:w-3/5">
            We start with a FREE introductory call to dicuss your project's
            specifications. This conversations allows us to provide an accurate
            quote and timeline to complete your project. If you decide to move
            forward, we'll have a more in-depth call to identify all of the
            specific details related to your project. Finally, development
            begins. Once completed, your code is placed into a GitHub repository
            (owned by you), and deployed.
          </p>
        </div>
        <div id="faq" className="mb-24"></div>
        <Faq />
        <div id="contact" className="pb-14"></div>
      </section>
      <section className="py-10 w-full">
        <Form />
      </section>
      <section className="w-full h-52 text-white bg-black">
        <Footer />
      </section>
    </>
  );
};

export default Home;
