import React from "react";
import { NavLink } from "react-router-dom";
import HamburgerButton from "../components/HamburgerButton";
import Sidebar from "./Sidebar";

const Header = () => {
  return (
    <>
      <div className="flex justify-between p-5">
        <div className="pt-2">
          <NavLink to="/" className="text-white text-center text-4xl">
            William Zero
          </NavLink>
        </div>
        <div className="">
          <HamburgerButton />
        </div>
      </div>
      <Sidebar />
    </>
  );
};

export default Header;
