import React from "react";

import { faqItems } from "../data/dummyData";
import { useStateContext } from "../contexts/ContextProvider";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";

const Faq = () => {
  const { faqClicked, handleFaqClick } = useStateContext();
  return (
    <div className="text-center text-2xl md:text-3xl">
      <div className="font-bold">Frequently Asked Questions</div>
      <div className="flex justify-center">
        <ul className="text-left m-5 md:w-3/5">
          {faqItems.map((item, index) => {
            return (
              <li key={item.id}>
                <div
                  className={`  ${
                    faqClicked[item.id] ? "bg-gray-100" : "bg-gray-300"
                  } border-1 border-gray-400 p-5`}
                  onClick={() => handleFaqClick(item.id)}
                >
                  <div className="grid grid-cols-6 ">
                    <div className="col-span-5 text-base font-bold mr-2">
                      {item.question}
                    </div>
                    {faqClicked[item.id] ? (
                      <FaChevronUp className="h-full place-self-end text-base" />
                    ) : (
                      <FaChevronDown className="h-full place-self-end text-base" />
                    )}
                  </div>
                  {faqClicked[item.id] && (
                    <div className="text-base mt-5">{item.answer}</div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default Faq;
