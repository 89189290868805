import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Home } from "./pages";
import Header from "./components/Header";
function App() {
  return (
    <>
      <div className="fixed w-screen bg-black">
        <Header />
      </div>
      <Routes>
        {/* Pages */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<Home />} />
        <Route path="/prices" element={<Home />} />
        <Route path="/how-it-works" element={<Home />} />
        <Route path="/get-started" element={<Home />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
}

export default App;
