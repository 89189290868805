import React from "react";

import { useStateContext } from "../contexts/ContextProvider";
import { IoCloseSharp } from "react-icons/io5";

const Iframe = () => {
  const { activeIframe, setActiveIframe } = useStateContext();

  const handlePopup = () => {
    setActiveIframe(!activeIframe);
  };

  const iframe =
    '<iframe style="height: 100vh; width:100%;" scrolling="no" src="https://calendly.com/dwilliamzero/30min" frameborder="no" allowtransparency="true" allowfullscreen="true"></iframe>';

  return (
    <>
      {activeIframe && (
        <div className="w-screen fixed top-0 bg-white z-20">
          <div className="flex justify-end m-5 text-3xl">
            <IoCloseSharp
              className="rounded-2xl bg-white hover:bg-gray-200"
              onClick={handlePopup}
            />
          </div>
          {/* <div className="flex justify-center text-2xl font-bold">
            Schedule A Consultation
          </div> */}
          <div
            dangerouslySetInnerHTML={{
              __html: iframe ? iframe : "",
            }}
          />
        </div>
      )}
    </>
  );
};

export default Iframe;
