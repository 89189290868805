import React from "react";
import { NavHashLink } from "react-router-hash-link";

import { useStateContext } from "../contexts/ContextProvider";
import { links } from "../data/dummyData";

const Sidebar = () => {
  const { activeMenu, setActiveMenu } = useStateContext();

  const normalLink =
    "flex items-center gap-5 pl-5 pt-3 pb-2.5 pr-20 rounded-lg text-md text-black hover:text-white hover:bg-black";

  const activeLink =
    "flex items-center gap-5 pl-5 pt-3 pb-2.5 rounded-lg text-white text-md";

  const handleCloseSideBar = () => {
    setActiveMenu(false);
  };
  const location = window.location.hash;

  return (
    <>
      {activeMenu && (
        <div className="h-screen w-full absolute top-20 bg-white z-1 pl-1 pr-5">
          {links.map((item) => {
            return (
              <NavHashLink
                to={`${item.target}`}
                key={item.id}
                onClick={handleCloseSideBar}
                className={location === item.target ? activeLink : normalLink}
                style={{
                  backgroundColor: location === item.target ? "black" : "",
                }}
              >
                {item.name.replace(/-/g, " ").toUpperCase()}
              </NavHashLink>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Sidebar;
